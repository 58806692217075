<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="ma-2"
        color="primary"
        x-small
        outlined
        v-on="on"
      >
        <v-icon left x-small>mdi-vector-difference</v-icon>Show Packet Diff
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Packet Diff</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-chip-group v-model="selectedPortChip" column>
          <v-chip
            v-for="port in selectedUseCase.ports"
            :key="port.id"
            filter
            outlined
            @click="selectPortForDiff(port)"
          >
            PORT {{ port.id }}</v-chip
          >
        </v-chip-group>
        <!--        <v-radio-group v-model="viewMode" dense row>-->
        <!--          <v-radio label="Tree View" value="tree"></v-radio>-->
        <!--          <v-radio label="Json View" value="json"></v-radio>-->
        <!--        </v-radio-group>-->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text
        v-if="selectedPort"
        style="height: 600px; overflow: scroll; overflow-x: hidden"
      >
        <v-row>
          <v-col>
            <v-card>
              <span class="subtitle-2 ma-2">PORT {{ selectedPort.id }}</span>
              <v-divider></v-divider>
              <v-card-text>
                <v-list-item
                  v-for="(packet, i) in getTxContents"
                  :key="i"
                  dense
                >
                  <v-list-item-content>
                    <packet-viewer :packet="packet"></packet-viewer>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-row
              v-for="(otherPort, i) in getOtherPortsRxContents"
              :key="i"
              dense
            >
              <v-col>
                <v-card>
                  <span class="subtitle-2 ma-2"
                  >PORT {{ otherPort.portId }}</span
                  >
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-list-item
                      v-for="(packet, j) in otherPort.rxdPacketContents"
                      :key="j"
                      dense
                    >
                      <v-list-item-content>
                        <PacketViewer :packet="packet"></PacketViewer>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn small class="primary" text @click="dialog = false">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import PacketViewer from '@/components/PacketViewer.vue';

export default {
  components: { PacketViewer },
  data() {
    return {
      dialog: false,
      selectedPort: null,
      selectedPortChip: null,
      viewMode: 'tree',
    };
  },
  computed: {
    ...mapState({
      results: (state) => state.usecase.selectedUseCaseResults,
      selectedUseCase: (state) => state.usecase.selectedUseCase,
    }),
    getRxContents() {
      const results = this.$store.state.usecase.selectedUseCaseResults;
      if (!results) {
        return [];
      }

      const index = results
        .map((item) => item.portId)
        .indexOf(this.selectedPort.id);
      if (index === -1) return [];

      return results[index].rxdPacketContents;
    },
    getTxContents() {
      const results = this.$store.state.usecase.selectedUseCaseResults;
      if (!results) {
        return [];
      }

      const index = results
        .map((item) => item.portId)
        .indexOf(this.selectedPort.id);
      if (index === -1) return [];

      return results[index].txdPacketContents;
    },
    getOtherPortsRxContents() {
      const results = this.$store.state.usecase.selectedUseCaseResults;
      if (!results) {
        return [];
      }

      return results.filter((item) => item.portId !== this.selectedPort.id);
    },
  },
  methods: {
    selectPortForDiff(port) {
      this.$store.commit('usecase/setSelectedDiffPort', port);
      this.selectedPort = port;
    },
  },
};
</script>

<style scoped></style>
