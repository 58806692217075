<template>
  <div>
    <div v-if="products.length > 10">
      <p class="overline">Recent Projects</p>
      <v-row dense>
        <ProductListItem
          v-for="product in getRecentProducts"
          :key="product.id"
          :product="product"
        ></ProductListItem>
      </v-row>
      <v-divider class="ma-2"></v-divider>
    </div>
    <div>
      <p class="overline">All Projects ({{ products.length }})</p>
      <v-row dense>
        <ProductListItem
          v-for="product in products"
          :key="product.id"
          :product="product"
        ></ProductListItem>
      </v-row>
    </div>
  </div>
</template>
<script>
import ProductListItem from '@/components/ProductListItem.vue';

export default {
  components: {
    ProductListItem,
  },
  props: {
    products: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    getRecentProducts() {
      return this.products
        .slice()
        .sort(
          (a, b) => new Date(b.last_accessed * 1000) - new Date(a.last_accessed * 1000),
        )
        .slice(0, 5);
    },
  },
};
</script>
