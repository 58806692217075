<template>
  <v-container fluid>
    <AppBar :show-options='false'></AppBar>
    <span class="subtitle-1">Choose platform to build and verify project pipeline</span>
    <v-row>
      <v-col cols="12">
        <PlatformList :platforms="platforms"></PlatformList>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import PlatformList from '@/components/PlatformList.vue';
import AppBar from '@/components/AppBar.vue';

export default {
  components: { AppBar, PlatformList },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      platforms: (state) => state.platform.platforms,
    }),
  },
  mounted() {
    this.$store.dispatch('platform/fetchPlatforms');
  },
};
</script>
