<template>
  <v-container fluid>
    <AppBar :show-options="false"></AppBar>
    <ProductList :products="products"></ProductList>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ProductList from '@/components/ProductList.vue';
import AppBar from '@/components/AppBar.vue';

export default {
  components: {
    ProductList,
    AppBar,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      selectedPlatform: (state) => state.platform.selectedPlatform,
      products: (state) => state.product.products,
    }),
  },
  mounted() {
    this.$store.dispatch('product/fetchProducts', this.selectedPlatform.id);
  },
};
</script>
