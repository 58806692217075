<template>
  <div>
    <v-treeview dense :open="open" :items="items" open-on-click></v-treeview>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    packet: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      open: [],
    };
  },
  computed: {
    ...mapState({
      selectedDiffPort: (state) => state.usecase.selectedDiffPort,
    }),
    items() {
      const layerItems = [];

      this.packet.layers.forEach((layer, index) => {
        const layerItem = {};
        layerItem.id = index + 1;
        layerItem.name = layer.name;
        layerItem.children = [];

        layer.layerFields.forEach((field, index1) => {
          layerItem.children.push({
            id: index1 + 1,
            name: `${field.name}:${field.value}`,
          });
        });

        layerItems.push(layerItem);
      });

      return layerItems;
    },
  },
};
</script>

<style scoped></style>
