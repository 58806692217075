<template>
  <div class="packet-builder-container">
    <v-row class="ma-0">
      <v-text-field
        v-model="newPacket.name"
        label="Name"
        required
      ></v-text-field>
    </v-row>
    <v-row class="ma-0">
      <div class="pa-4 build-area">
        <table>
          <tr>
            <td
              v-for="(layer, i) of newPacket.layers"
              :key="i"
              @click="showLayerEditor(layer)"
            >
              <LayerItem :layer="layer"></LayerItem>
            </td>
            <td>
              <v-icon
                style="cursor: pointer"
                color="primary"
                @click="showMenu($event)"
              >mdi-plus</v-icon
              >
            </td>
          </tr>
          <tr>
            <td v-for="(layer, i) of newPacket.layers" :key="i">
              <v-icon
                small
                style="cursor: pointer"
                color="primary"
                @click="setIndexAndShowDialog($event, i)"
              >mdi-plus</v-icon
              >
              <v-icon
                small
                style="cursor: pointer"
                color="primary"
                @click="removeLayer(i)"
              >mdi-minus</v-icon
              >
            </td>
          </tr>
        </table>
      </div>
      <v-row class="ma-0">
        <v-btn small class="primary ma-1" text @click="reset">reset</v-btn>
        <v-btn small class="primary ma-1" text @click="createNewPacket"
        >Save</v-btn
        >
        <v-btn
          v-if="loggedInUser.role === 1"
          small
          class="primary ma-1"
          text
          @click="createNewPacketTemplate"
        >Save As Template</v-btn
        >
      </v-row>
    </v-row>
    <v-row class="ma-0">
      <v-col>
        <v-card
          v-if="layerEditorDialog"
          flat
          tile
          style="background-color: #f8f8f8"
        >
          <v-card-title>Edit Layer Fields</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <LayerEditor></LayerEditor>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card>
        <v-card-title
        >Available Layers({{ filteredLayerList.length }})</v-card-title
        >
        <v-divider></v-divider>
        <v-text-field
          v-model="searchLayer"
          label="Search Layer"
          required
          class="ma-2"
          clearable
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-text>
          <v-virtual-scroll
            :items="filteredLayerList"
            height="300"
            width="400"
            item-height="40"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.name">
                <v-list-item-content @click="addSelectedLayer(item)">
                  <LayerItem :layer="item"></LayerItem>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import LayerEditor from '@/components/LayerEditor.vue';
import LayerItem from '@/components/LayerItem.vue';

export default {
  components: {
    LayerEditor,
    LayerItem,
  },
  data: () => ({
    dialog: false,
    menu: false,
    layerEditorDialog: false,
    newPacket: {
      name: '',
      layers: [],
    },
    searchLayer: '',
    selectedIndex: -1,
    x: 0,
    y: 0,
  }),
  computed: {
    ...mapGetters(['loggedInUser']),
    ...mapState({
      layers: (state) => state.layer.layers,
      selectedPacketTemplate: (state) => state.packet.selectedPacketTemplate,
    }),
    filteredLayerList() {
      return this.layers.filter(
        (layer) => layer.name.toLowerCase().includes(this.searchLayer.toLowerCase()),
      );
    },
  },
  watch: {
    selectedPacketTemplate() {
      this.newPacket = JSON.parse(JSON.stringify(this.selectedPacketTemplate));
    },
  },
  created() {
    this.fetchLayers();
    this.fetchLayerDescription();
  },
  methods: {
    ...mapActions({
      fetchLayers: 'layer/fetchLayers',
      fetchLayerDescription: 'layer/fetchLayerDescription',
    }),
    addSelectedLayer(layer) {
      // insert at last
      if (this.selectedIndex === -1) {
        this.newPacket.layers.push(layer);
      } else {
        this.newPacket.layers.splice(this.selectedIndex, 0, layer);
      }
      this.menu = false;
      this.selectedIndex = -1;
    },
    createNewPacket() {
      if (this.newPacket.name === '') {
        this.$store.dispatch(
          'showNotification',
          {
            message: 'Please provide a name',
            timeout: -1,
            type: 'info',
          },
          { root: true },
        );
      } else {
        this.$store.dispatch('packet/createPacket', this.newPacket);
        this.newPacket.name = '';
        this.newPacket.layers = [];
        this.layerEditorDialog = false;
      }
    },
    createNewPacketTemplate() {
      this.$store.dispatch('packet/createPacketTemplate', this.newPacket);
      this.newPacket.name = '';
      this.newPacket.layers = [];
    },
    showLayerEditor(layer) {
      this.$store.dispatch('layer/selectLayer', layer);
      this.layerEditorDialog = true;
    },
    setIndexAndShowDialog(e, index) {
      this.selectedIndex = index;
      this.menu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menu = true;
      });
    },
    showMenu(e) {
      this.menu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menu = true;
      });
    },
    removeLayer(index) {
      this.newPacket.layers.splice(index, 1);
    },
    reset() {
      this.newPacket.name = '';
      this.newPacket.layers = [];
    },
  },
};
</script>

<style scoped>
.col .col-12 {
  padding-bottom: 0;
  padding-top: 0;
}
.v-list-item__content {
  padding: 2px;
}
.build-area {
  border: 1px dotted black;
  width: 100%;
  height: 100px;
  text-align: center;
}
</style>
