<template>
  <v-row class="ma-0">
    <LayerItem
      v-for="(layer, i) in packet.layers"
      :key="i"
      :layer="layer"
      :show-tooltip="showTooltip"
    ></LayerItem>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LayerItem from '@/components/LayerItem.vue';

export default {
  components: {
    LayerItem,
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    packet: {
      type: Object,
      default() {
        return {};
      },
    },
    selectable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    ...mapState('layer', ['layerDescription']),
  },
  methods: {
    ...mapActions({
      deletePacket: 'packet/deletePacket',
    }),
    selectPacket() {
      if (this.selectable) {
        this.$store.dispatch('packet/selectPacket', this.packet);
      }
    },
    selectLayer() {
      if (this.selectable) {
        this.$store.dispatch('layer/selectLayer', this.layer);
      }
    },

    isSelectedLayer() {
      if (!this.selectable) return false;
      return (
        this.$store.state.layer.selectedLayer
        && this.$store.state.layer.selectedLayer.name === this.layer.name
      );
    },
    layerColor(layer) {
      if (!this.layerDescription[layer.name]) return '';
      return this.layerDescription[layer.name].color;
    },
  },
};
</script>

<style lang="scss" scoped></style>
