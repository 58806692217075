<template>
  <splitpanes
    id="switch-split-pane"
    horizontal
    class="default-theme green-border"
  >
    <pane
      size="80"
      min-size="20"
      max-size="100"
      style="overflow: scroll; overflow-x: hidden; background: #ffffff"
    >
      <v-row class="ma-0 pa-2 fill-height">
        <v-col cols="12" class="pa-0">
          <div class="main-wrapper">
            <v-btn
            v-if="showPortContents"
            class="ma-2"
            color="primary"
            x-small
            outlined
            @click="toggleShowPortContents"
            >
            <v-icon left x-small>mdi-eye-off</v-icon>Port Contents
            </v-btn>
            <v-btn
              v-if="!showPortContents"
              class="ma-2"
              color="primary"
              x-small
              outlined
              @click="toggleShowPortContents"
            >
              <v-icon left x-small>mdi-eye</v-icon>Port Contents
            </v-btn>
            <ShowPacketDiffButton />
            <ShowLogsButton v-if="selectedUseCase" />
            <v-btn
              v-if="selectedUseCaseResults"
              class="ma-2"
              color="primary"
              x-small
              outlined
              @click="takeSnapshot()"
            >
              <v-icon left x-small>mdi-content-copy</v-icon>Take Snapshot</v-btn
            >
            <v-divider></v-divider>
            <v-row class="pa-2 mt-2 fill-height" dense>
              <v-col cols="4" class="pa-0">
                <div
                  class="d-flex flex-column justify-space-around"
                  style="height: 100%"
                >
                  <div
                    v-for="port in leftHalfPorts"
                    :id="`left-box-${port.id}`"
                    :key="port.id"
                    :ref="`left-box-${port.id}`"
                    class="ma-1"
                  >
                    <TrafficPort
                      :port="port"
                      :show-contents="showPortContents"
                      pos="left"
                    ></TrafficPort>
                  </div>
                </div>
              </v-col>
              <v-col cols="1" class="pa-0">
                <div class="d-flex flex-column justify-space-around" style="height: 100%">
                  <div
                    v-for="port in leftHalfPorts"
                    :key="port.id"
                    class="ma-0 svg-wrapper"
                  >
                    <svg style="margin: 0 auto; display: block; height: 30px;">
                      <line
                        x1="0"
                        y1="15"
                        x2="70%"
                        y2="15"
                        style="stroke: rgb(0, 0, 0); stroke-width: 2"
                      />
                      <image
                        :href="require('../assets/port.png')"
                        height="30"
                        width="30"
                        x="calc(100% - 30px)"
                        y="0"
                      />
                    </svg>
                  </div>
                </div>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-card
                  class="d-flex flex-column align-center"
                  style="height: 100%"
                >
                  <v-card-text
                    class="d-flex flex-column align-center"
                    style="height: 100%"
                  >
                    <v-img
                      :src="require(`../assets/${selectedPlatform.img}`)"
                      class="my-3 align-self-center"
                      contain
                    />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="1" class="pa-0">
                <div class="d-flex flex-column justify-space-around" style="height: 100%">
                  <div
                    v-for="port in rightHalfPorts"
                    :key="port.id"
                    class="ma-0 svg-wrapper"
                  >
                    <svg style="margin: 0 auto; display: block; height: 30px;">
                      <line
                        x1="100%"
                        y1="15"
                        x2="30%"
                        y2="15"
                        style="stroke: rgb(0, 0, 0); stroke-width: 2"
                      />
                      <image
                        :href="require('../assets/port.png')"
                        height="30"
                        width="30"
                        x="0"
                        y="calc(100% - 30px)"
                      />
                    </svg>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div
                  class="d-flex flex-column justify-space-around"
                  style="height: 100%"
                >
                  <div
                    v-for="port in rightHalfPorts"
                    :id="`right-box-${port.id}`"
                    :key="port.id"
                    :ref="`right-box-${port.id}`"
                    class="ma-1"
                  >
                    <TrafficPort
                      :port="port"
                      :show-contents="showPortContents"
                      pos="right"
                    ></TrafficPort>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </pane>
    <pane style="overflow: scroll; overflow-x: hidden; background: #ffffff">
      <div v-if="selectedUseCase" class="table-wrapper">
        <v-row class="ma-0 pa-0">
          <v-col
            v-if="
              selectedUseCase &&
              pipelineInfo &&
              selectedTable &&
              (selectedTableType === 'ingress' ||
                selectedTableType === 'egress')
            "
            cols="12"
          >
            <span class="overline">Add Table Entry</span>
            <v-simple-table dense>
              <thead>
              <tr>
                <th v-for="key in selectedTable.keys" :key="key.name">
                  {{ key.name }} ({{ key.match_type }})
                </th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td v-for="key in selectedTable.keys" :key="key.name">
                  <label>
                    <v-text-field
                      :ref="key.name"
                      :name="key.name"
                      required
                    ></v-text-field>
                  </label>
                </td>
                <td>
                  <v-select
                    v-model="selectedAction"
                    :items="availableActions"
                  ></v-select>
                </td>
                <td
                  v-for="actionParam in availableActionParams"
                  :key="actionParam.name"
                >
                  <v-text-field
                    :ref="actionParam.name"
                    :name="actionParam.name"
                    required
                    :label="actionParam.name"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn icon color="success" tile @click="saveTableEntry()">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col
            v-if="
              selectedUseCase && pipelineInfo && selectedTableType === 'traffic'
            "
          >
            <!-- Multicast Group Table Entry -->
            <v-divider v-if="selectedUseCase"></v-divider>
            <span class="ma-5 subtitle-1">Multicast</span>
            <MulticastGroupEntryForm
              v-if="selectedUseCase"
            ></MulticastGroupEntryForm>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row
          v-if="
            selectedUseCase && selectedTable && selectedTableType === 'ingress'
          "
          class="ma-0"
        >
          <v-col cols="12">
            <TableEntries :entries="tableEntries"></TableEntries>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="selectedUseCase && selectedTableType === 'traffic'"
            cols="12"
          >
            <MulticastGroupEntries
              :entries="multicastGroupEntries"
            ></MulticastGroupEntries>
          </v-col>
        </v-row>
        <v-row
          v-if="
            selectedUseCase && selectedTable && selectedTableType === 'egress'
          "
          class="ma-0"
        >
          <v-col cols="12">
            <TableEntries :entries="tableEntries"></TableEntries>
          </v-col>
        </v-row>
      </div>
    </pane>
  </splitpanes>
</template>
<script>
import { Pane, Splitpanes } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import TrafficPort from '@/components/TrafficPort.vue';
import { mapState } from 'vuex';
import ShowLogsButton from '@/components/ShowLogsButton.vue';
import ShowPacketDiffButton from '@/components/ShowPacketDiffButton.vue';
import TableEntries from '@/components/TableEntries.vue';
import MulticastGroupEntries from '@/components/MulticastGroupEntries.vue';
import MulticastGroupEntryForm from '@/components/MulticastGroupEntryForm.vue';

export default {
  components: {
    TrafficPort,
    Pane,
    Splitpanes,
    ShowPacketDiffButton,
    ShowLogsButton,
    TableEntries,
    MulticastGroupEntries,
    MulticastGroupEntryForm,
  },
  data() {
    return {
      selectedAction: null,
    };
  },
  computed: {
    ...mapState({
      selectedPlatform: (state) => state.platform.selectedPlatform,
      selectedProduct: (state) => state.product.selectedProduct,
      selectedUseCase: (state) => state.usecase.selectedUseCase,
      showPortContents: (state) => state.usecase.showPortContents,
      selectedTable: (state) => state.product.selectedTable,
      selectedTableType: (state) => state.product.selectedTableType,
      pipelineInfo: (state) => state.product.pipelineInfo,
      selectedUseCaseResults: (state) => state.usecase.selectedUseCaseResults,
    }),
    leftHalfPorts() {
      if (this.selectedUseCase) {
        const halfwayThrough = Math.ceil(this.selectedUseCase.ports.length / 2);
        return this.selectedUseCase.ports.slice(0, halfwayThrough);
      }
      return [];
    },
    rightHalfPorts() {
      if (this.selectedUseCase) {
        const halfwayThrough = Math.ceil(this.selectedUseCase.ports.length / 2);
        return this.selectedUseCase.ports.slice(
          halfwayThrough,
          this.selectedUseCase.ports.length,
        );
      }
      return [];
    },
    availableActions() {
      if (!this.pipelineInfo) return [];
      return this.pipelineInfo.actions
        .map((item) => item.name)
        .filter((name) => this.selectedTable.actions.includes(name));
    },
    availableActionParams() {
      const index = this.pipelineInfo.actions
        .map((item) => item.name)
        .indexOf(this.selectedAction);
      if (index === -1) return [];
      return this.pipelineInfo.actions[index].runtime_data;
    },
    tableEntries() {
      if (!this.selectedUseCase || !this.selectedTable) return [];
      return this.selectedUseCase.tableEntries.filter(
        (item) => item.table === this.selectedTable.name,
      );
    },
    multicastGroupEntries() {
      return this.selectedUseCase.multicastGroupEntries;
    },
  },
  methods: {
    toggleShowPortContents() {
      this.$store.commit(
        'usecase/setShowPortContents',
        !this.showPortContents,
      );
    },
    takeSnapshot() {
      this.$store.dispatch('usecase/takeSnapshot');
    },
    saveTableEntry() {
      const entry = {
        id: this.selectedUseCase.tableEntries.length + 1,
        table: this.selectedTable.name,
        match: {},
        action_name: '',
        action_params: {},
      };

      // Get Match Fields
      this.selectedTable.keys.forEach((key) => {
        if (key.match_type === 'lpm') {
          const values = document
            .querySelector(`input[name='${key.name}']`)
            .value.split(',');
          values[1] = parseInt(values[1], 10);
          entry.match[key.name] = values;
        } else {
          let value = null;
          const cValue = document.querySelector(
            `input[name='${key.name}']`,
          ).value;
          if (/^-?\d+$/.test(cValue)) {
            value = parseInt(cValue, 10);
          } else {
            value = cValue;
          }
          entry.match[key.name] = value;
        }

        // reset value
        document.querySelector(`input[name='${key.name}']`).value = '';
      });

      // Get Selected Action
      entry.action_name = this.selectedAction;

      // Get Action Params
      this.availableActionParams.forEach((param) => {
        const paramValue = document.querySelector(
          `input[name='${param.name}']`,
        ).value;

        const parsed = parseInt(paramValue, 10);
        if (!Number.isNaN(parsed) && paramValue.length === parsed.toString().length) {
          entry.action_params[param.name] = parsed;
        } else entry.action_params[param.name] = paramValue;

        // reset value
        document.querySelector(`input[name='${param.name}']`).value = '';
      });

      this.selectedAction = null;

      this.$store.dispatch('usecase/addTableEntry', entry);
    },

    deleteMulticastGroupEntry(entryId) {
      this.$store.dispatch('usecase/deleteMulticastGroupEntry', entryId);
    },
  },
};
</script>
<style scoped lang="sass">
#switch-split-pane
  height: calc(100vh - 48px - 21px)
.default-theme
  background: #ffffff
.border
  border: 1px solid black
.svg-wrapper
  display: flex
  align-items: center
</style>
