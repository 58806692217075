<template>
    <v-col>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card v-if="platform" width="200" hover class="ma-2">
            <div class="subtitle-2 pa-1 ml-1">{{ platform.name }}</div>
            <v-divider></v-divider>
            <div class="pa-2">
              <v-img :src="require(`../assets/${platform.img}`)" contain :aspect-ratio="1"></v-img>
            </div>
            <v-divider></v-divider>
            <v-fade-transition>
              <v-overlay v-if="hover" absolute color="#036358">
                <v-btn small @click="startPlatform">start platform</v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-col>
</template>

<script>
export default {
  name: 'PlatformListItem',
  props: {
    platform: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    startPlatform() {
      this.$store.dispatch('platform/selectPlatform', this.platform);
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped></style>
