<template>
  <v-row class="ma-0">
    <PacketList
      :packets="stream.packets"
      :can-delete="false"
      :show-title="false"
      :show-tooltip="showTooltip"
    ></PacketList>
  </v-row>
</template>

<script>
import PacketList from '@/components/PacketList.vue';

export default {
  components: {
    PacketList,
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    stream: {
      type: Object,
      default() {
        return {};
      },
    },
    selectable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
