<template>
  <v-row dense>
    <div class="d-flex justify-space-between">
      <PlatformListItem
        v-for="platform in platforms"
        :key="platform.id"
        :platform="platform"
      ></PlatformListItem>
    </div>
  </v-row>
</template>

<script>
import PlatformListItem from './PlatformListItem.vue';

export default {
  name: 'PlatformList',
  components: { PlatformListItem },
  props: {
    platforms: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped></style>
