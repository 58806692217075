<template>
  <splitpanes v-if="selectedUseCase !== null" class="default-theme">
    <pane
      size="22"
      min-size="22"
      max-size="30"
    >
      <div class="pa-2 side-panel">
        <v-tabs v-model="tab" style="flex: 0">
          <v-tab href="#usecase">usecase</v-tab>
          <v-tab href="#packet">packet builder</v-tab>
          <v-tab href="#stream">stream builder</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            key="1"
            class="align-center pl-0 pr-0 pt-4"
            value="usecase"
          >
            <UseCasePanel />
          </v-tab-item>
          <v-tab-item
            key="2"
            class="align-center pa-2"
            value="packet"
          >
            <PacketSearchPanel />
            <v-divider></v-divider>
            <div class="text-center overline">
              Available Packets({{ packets.length }})
            </div>
            <v-card-text class="scrollable-content packet-list">
              <PacketList
                :packets="packets"
                :show-tooltip="true"
              ></PacketList>
            </v-card-text>
          </v-tab-item>
          <v-tab-item
            key="3"
            class="align-center pa-2"
            value="stream"
          >
            <StreamSearchPanel />
            <v-divider></v-divider>
            <div class="text-center overline">
              Available Streams({{ streams.length }})
            </div>
            <v-card-text class="scrollable-content stream-list">
              <StreamList
                :streams="streams"
                :show-tooltip="true"
              ></StreamList>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </pane>
    <pane>
      <div class="pa-2 main-panel">
        <SingleSwitch v-if="tab === 'usecase'"></SingleSwitch>
        <PacketBuilder v-if="tab === 'packet'"></PacketBuilder>
        <StreamBuilder v-if="tab === 'stream'"></StreamBuilder>
      </div>
    </pane>
  </splitpanes>
</template>
<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import UseCasePanel from '@/components/UseCasePanel.vue';
import { mapState } from 'vuex';
import PacketSearchPanel from '@/components/PacketSearchPanel.vue';
import PacketList from '@/components/PacketList.vue';
import StreamSearchPanel from '@/components/StreamSearchPanel.vue';
import StreamList from '@/components/StreamList.vue';
import PacketBuilder from '@/components/PacketBuilder.vue';
import StreamBuilder from '@/components/StreamBuilder.vue';
import SingleSwitch from '@/components/SingleSwitch.vue';

export default {
  components: {
    UseCasePanel,
    Splitpanes,
    Pane,
    PacketSearchPanel,
    PacketList,
    StreamSearchPanel,
    StreamList,
    PacketBuilder,
    StreamBuilder,
    SingleSwitch,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapState({
      layers: (state) => state.layer.layers,
      packets: (state) => state.packet.packets,
      streams: (state) => state.stream.streams,
      selectedUseCase: (state) => state.usecase.selectedUseCase,
    }),
  },
};
</script>
<style lang="sass" scoped>
.side-panel,
.main-panel
  background-color: #ffffff
  display: flex
  flex-direction: column
  justify-content: flex-start
  min-height: calc(100vh - 64px - 48px)
  max-height: calc(100vh - 64px -  48px)
  overflow-y: auto
  overflow-x: hidden
  border: 1px solid #E0E0E0
.scrollable-content
  overflow: scroll
  overflow-x: hidden
  overflow-y: auto
.packet-list,
.stream-list
  height: calc(100vh - 260px)
</style>
