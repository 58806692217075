import Http from '@/services/http';

const stream = {
  namespaced: true,
  state: () => ({
    streams: [],
  }),
  mutations: {
    setStreams(state, streams) {
      state.streams = streams;
    },
    setSelectedStream(state, selectedStream) {
      state.selectedStream = selectedStream;
    },
    setSelectedStreamTemplate(state, template) {
      state.selectedStreamTemplate = template;
    },
    addStream(state, newStream) {
      state.streams.push(newStream);
    },
    removeStream(state, streamId) {
      const index = state.streams.map((item) => item.id).indexOf(streamId);
      state.streams.splice(index, 1);
    },
    setSearchResults(state, streams) {
      state.searchResults = streams;
    },
  },
  actions: {
    async fetchStreams({ commit }) {
      const response = await Http.get('/streams');
      commit('setStreams', response.data.streams);
    },
    async createStream({ commit }, newStream) {
      const response = await Http.post('/streams', {
        stream: {
          name: newStream.name,
          packets: newStream.packets,
          count: newStream.count,
        },
      });
      commit('addStream', response.data.stream);
    },
    // eslint-disable-next-line no-unused-vars
    async createStreamTemplate({ commit }, newStream) {
      await Http.post('/stream_templates', {
        stream: {
          name: newStream.name,
          packets: newStream.packets,
          count: newStream.count,
        },
      });
    },
    async deleteStream({ commit }, streamId) {
      await Http.delete(`/streams/${streamId}`);
      commit('removeStream', streamId);
    },
    // eslint-disable-next-line no-unused-vars
    updatePacket({ commit }) {},
    selectStream({ commit }, selectedStream) {
      commit('setSelectedStream', selectedStream);
    },
    selectStreamTemplate({ commit }, template) {
      commit('setSelectedStreamTemplate', template);
    },
    async searchTemplates({ commit }, searchText) {
      const response = await Http.get(`/stream_templates?q=${searchText}`);
      commit('setSearchResults', response.data.streams);
    },
  },
  getters: {

  },
};
export default stream;
