<template>
  <v-simple-table>
    <thead>
    <tr>
      <th>MCAST GROUP ID</th>
      <th>MCAST PORT ID</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(entry, i) in entries" :key="i">
      <td>{{ entry.multicast_group_id }}</td>
      <td>
          <span v-for="(replica, j) in entry.replicas" :key="j">
            <v-chip>{{ replica.egress_port }}</v-chip
            >&nbsp;</span
          >
      </td>
      <td>
        <v-btn small @click="deleteEntry(entry.multicast_group_id)">
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    entries: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    deleteEntry(entryId) {
      this.$store.dispatch('usecase/deleteMulticastGroupEntry', entryId);
    },
  },
};
</script>
