<template>
  <v-menu
    :value="streamMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
  >
    <v-card>
      <v-card-title
      >Available Streams({{ filteredStreams.length }})</v-card-title
      >
      <v-divider></v-divider>
      <v-text-field
        v-model="searchStream"
        label="Search Streams"
        required
        class="ma-2"
      ></v-text-field>
      <v-divider></v-divider>
      <v-card-text style="height: 500px" class="scrollable-content">
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(stream, i) in filteredStreams"
              :key="i"
              dense
            >
              <v-list-item-content @click="addStream(stream)">
                <v-list-item-title>{{ stream.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    streamMenu: {
      type: Boolean,
      default() {
        return false;
      },
    },
    x: {
      type: Number,
      default() {
        return 0;
      },
    },
    y: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      searchStream: '',
    };
  },
  computed: {
    ...mapState({
      streams: (state) => state.stream.streams,
    }),
    filteredStreams() {
      return this.streams.filter((stream) => stream.name
        .toLowerCase()
        .includes(this.searchStream.toLowerCase()));
    },
  },
  methods: {
    addStream(stream) {
      this.$emit('addStream', stream);
    },
  },
};
</script>
