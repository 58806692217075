<template>
  <v-btn icon small @click="deletePacket">
    <v-icon color="red">mdi-delete</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    packetId: {
      type: String,
      default: '',
    },
  },
  methods: {
    deletePacket() {
      this.$store.dispatch('packet/deletePacket', this.packetId);
    },
  },
};
</script>

<style scoped></style>
