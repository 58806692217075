<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="ma-2"
        color="primary"
        x-small
        outlined
        v-on="on"
      >
        <v-icon left x-small>mdi-text-subject</v-icon>Show Logs
      </v-btn>
    </template>
    <v-card>
      <v-card-title>BMv2 Logs</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="useCaseLogs" style="height: 78vh; overflow: scroll">
        <p v-for="(log, index) in useCaseLogs" :key="index">{{ log }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn small class="primary" text @click="dialog = false">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      useCaseLogs: (state) => state.usecase.selectedUseCaseLogs,
      selectedUseCase: (state) => state.usecase.selectedUseCase,
    }),
  },
  methods: {},
};
</script>

<style scoped></style>
