import axios from 'axios';

const currentUser = JSON.parse(localStorage.getItem('currentUser')) || '';
const Http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Bearer ${currentUser.token}`,
  },
});

export default Http;
