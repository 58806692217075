<template>
  <div>
    <span class="overline">Table Entries</span>
    <v-divider></v-divider>
    <table id="table-entries-table">
      <colgroup>
        <col style="width: 5%" />
        <col style="width: 35%" />
        <col style="width: 20%" />
        <col style="width: 35%" />
      </colgroup>
      <thead>
      <tr class="overline">
        <th>#</th>
        <th>Match</th>
        <th>Action</th>
        <th>Action Params</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="entry in entries" :key="entry.id">
        <td>
          <v-btn icon x-small color="red" @click="deleteEntry(entry.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </td>
        <td>
          <table>
            <tbody>
            <tr v-for="(value, name) in entry.match" :key="name">
              <td>
                <v-chip label class="purple lighten-4 pa-1" small>{{
                    name
                  }}</v-chip>
              </td>
              <td>
                <v-text-field
                  :value="value.toString()"
                  @change="updateTableEntry(entry.id, name, $event, 'match')"
                ></v-text-field>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
        <td>
          <v-chip label class="deep-orange lighten-4 pa-1" small>
            {{ entry.action_name }}
          </v-chip>
        </td>
        <td>
          <table>
            <tbody>
            <tr v-for="(value, name) in entry.action_params" :key="name">
              <td>
                <v-chip label class="cyan lighten-4 pa-1" small>
                  {{ name }}
                </v-chip>
              </td>
              <td>
                <v-text-field
                  :value="value.toString()"
                  :length="30"
                  @change="updateTableEntry(entry.id, name, $event, 'action')"
                ></v-text-field>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    entries: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    deleteEntry(entryId) {
      this.$store.dispatch('usecase/deleteTableEntry', entryId);
    },
    updateTableEntry(entryId, name, value, type) {
      this.$store.dispatch('usecase/updateTableEntry', {
        entryId,
        name,
        value,
        type,
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th {
  height: 50px;
}
th,
td {
  padding: 5px;
  text-align: center;
}
tbody > tr:hover {
  background-color: #f5f5f5;
}

#table-entries-table {
  width: 100%;
}
</style>
