import Vue from 'vue';
import VueRouter from 'vue-router';
import Product from '@/views/Product.vue';
import Home from '@/views/Home.vue';
import Platform from '@/views/Platform.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Platform',
    component: Platform,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/products',
    name: 'Products',
    component: Product,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
