import Http from '@/services/http';

const usecase = {
  namespaced: true,
  state: () => ({
    useCases: [],
    selectedUseCase: null,
    isUseCaseRunning: false,
    selectedUseCaseResults: null,
    selectedUseCaseLogs: null,
    showPortContents: true,
  }),
  mutations: {
    setUseCases(state, useCases) {
      state.useCases = useCases;
    },
    setSelectedUseCase(state, selectedUseCase) {
      state.selectedUseCase = selectedUseCase;
    },
    addUseCase(state, newUseCase) {
      state.useCases.push(newUseCase);
    },
    setSelectedUseCaseById(state, useCaseId) {
      const index = state.useCases.findIndex((item) => item.id === useCaseId);
      state.selectedUseCase = state.useCases[index];
    },
    removeUseCase(state, useCaseId) {
      const index = state.useCases.findIndex((item) => item.id === useCaseId);
      state.useCases.splice(index, 1);
    },
    setUseCaseDescription(state, { useCaseId, description }) {
      const index = state.useCases.findIndex((item) => item.id === useCaseId);
      state.useCases[index].description = description;
    },
    setUseCaseRunningState(state, runningState) {
      state.isUseCaseRunning = runningState;
    },
    setUseCaseResults(state, results) {
      state.selectedUseCaseResults = results;
    },
    setUseCaseLogs(state, logs) {
      state.selectedUseCaseLogs = logs;
    },
    updateTableEntry(state, {
      entryId, name, value, type,
    }) {
      const entry = state.selectedUseCase.tableEntries.find(
        (item) => item.id === entryId,
      );
      if (Array.isArray(entry.match[name])) {
        const values = value.split(',');
        values[1] = parseInt(values[1], 10);
        if (type === 'match') {
          entry.match[name] = values;
        } else {
          entry.action_params[name] = values;
        }
      } else if (type === 'match') {
        entry.match[name] = value;
      } else {
        entry.action_params[name] = value;
      }
    },
    addTableEntry(state, tableEntry) {
      state.selectedUseCase.tableEntries.push(tableEntry);
    },
    addMulticastGroupEntry(state, multicastGroupEntry) {
      state.selectedUseCase.multicastGroupEntries.push(multicastGroupEntry);
    },
    removeTableEntry(state, tableEntryId) {
      const index = state.selectedUseCase.tableEntries
        .map((item) => item.id)
        .indexOf(tableEntryId);
      state.selectedUseCase.tableEntries.splice(index, 1);
    },
    removeMulticastGroupEntry(state, entryId) {
      const index = state.selectedUseCase.multicastGroupEntries
        .map((item) => item.multicast_group_id)
        .indexOf(entryId);
      state.selectedUseCase.multicastGroupEntries.splice(index, 1);
    },
    addStream(state, { portId, newStream }) {
      const index = state.selectedUseCase.ports
        .map((item) => item.id)
        .indexOf(portId);
      state.selectedUseCase.ports[index].streams.push(newStream);
    },
    addPacket(state, { portId, newPacket }) {
      const index = state.selectedUseCase.ports
        .map((item) => item.id)
        .indexOf(portId);
      state.selectedUseCase.ports[index].packets.push(newPacket);
    },
    removePacket(state, { portId, packetId }) {
      const portIndex = state.selectedUseCase.ports
        .map((item) => item.id)
        .indexOf(portId);
      const packetIndex = state.selectedUseCase.ports[portIndex].packets
        .map((item) => item.id)
        .indexOf(packetId);
      state.selectedUseCase.ports[portIndex].packets.splice(packetIndex, 1);
    },
    removeStream(state, { portId, streamId }) {
      const portIndex = state.selectedUseCase.ports
        .map((item) => item.id)
        .indexOf(portId);
      const streamIndex = state.selectedUseCase.ports[portIndex].streams
        .map((item) => item.id)
        .indexOf(streamId);
      state.selectedUseCase.ports[portIndex].streams.splice(streamIndex, 1);
    },
    addPort(state, port) {
      state.selectedUseCase.ports.push(port);
    },
    removePort(state, portId) {
      const index = state.selectedUseCase.ports.findIndex(
        (item) => item.id === portId,
      );
      state.selectedUseCase.ports.splice(index, 1);
    },
    setSelectedDiffPort(state, port) {
      state.selectedDiffPort = port;
    },
    setShowPortContents(state, value) {
      state.showPortContents = value;
    },
    setUseCaseName(state, { useCaseId, name }) {
      const index = state.useCases.findIndex((item) => item.id === useCaseId);
      state.useCases[index].name = name;
    },
  },
  actions: {
    async fetchUseCases({ commit }, productId) {
      const response = await Http.get(`/usecases?product_id=${productId}`);
      commit('setUseCases', response.data.usecases);
    },

    async createUseCase({ commit }, useCase) {
      const response = await Http.post('/usecases', {
        usecase: {
          name: useCase.name,
          description: useCase.description,
          productId: useCase.productId,
          ports: [],
          tableEntries: [],
        },
      });
      commit('addUseCase', response.data.usecase);
      commit('setSelectedUseCase', response.data.usecase);
    },

    async deleteUseCase({ commit }, useCaseId) {
      await Http.delete(`/usecases/${useCaseId}`);
      commit('removeUseCase', useCaseId);
    },

    async duplicateUseCase({ commit }, useCaseId) {
      const response = await Http.post(`/usecases/${useCaseId}/duplicate`);
      commit('addUseCase', response.data.usecase);
      commit('setSelectedUseCase', response.data.usecase);
    },

    selectUseCase({ commit }, useCase) {
      commit('setSelectedUseCase', useCase);
    },

    async updateUseCaseDescription(
      { commit, getters },
      { useCaseId, description },
    ) {
      commit('setUseCaseDescription', { useCaseId, description });

      await Http.put(`/usecases/${useCaseId}`, {
        usecase: getters.getUseCaseById(useCaseId),
      });
    },

    async executeSelectedUseCase({ commit, state }) {
      commit('setUseCaseRunningState', true);

      const response = await Http.post(
        `/usecases/${state.selectedUseCase.id}/execute`,
      );
      commit('setUseCaseResults', response.data.results);
      commit('setUseCaseLogs', response.data.logs);

      commit('setUseCaseRunningState', false);
    },
    async addMulticastGroupEntry({ commit, state }, multicastGroupEntry) {
      commit('addMulticastGroupEntry', multicastGroupEntry);

      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },
    async deleteMulticastGroupEntry({ commit, state }, entryId) {
      commit('removeMulticastGroupEntry', entryId);

      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async addTableEntry({ commit, state }, tableEntry) {
      commit('addTableEntry', tableEntry);

      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async deleteTableEntry({ commit, state }, entryId) {
      commit('removeTableEntry', entryId);

      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async updateTableEntry({ commit, state }, {
      entryId, name, value, type,
    }) {
      commit('updateTableEntry', {
        entryId, name, value, type,
      });
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async addPortToUseCase({ commit, state }) {
      const newPort = {
        id: 1,
        streams: [],
        packets: [],
      };
      if (state.selectedUseCase.ports.length > 0) {
        newPort.id = Math.max(...state.selectedUseCase.ports.map((item) => item.id)) + 1;
      }

      commit('addPort', newPort);

      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async removePortFromUseCase({ commit, state }, portId) {
      commit('removePort', portId);
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async addStream({ commit, state }, { portId, newStream }) {
      commit('addStream', { portId, newStream });
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },
    async addPacket({ commit, state }, { portId, newPacket }) {
      commit('addPacket', { portId, newPacket });
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async deletePacket({ commit, state }, { portId, packetId }) {
      commit('removePacket', { portId, packetId });
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async deleteStream({ commit, state }, { portId, streamId }) {
      commit('removeStream', { portId, streamId });
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async takeSnapshot({ commit, state }) {
      commit('SET_SNAPSHOT');
      await Http.put(`/usecases/${state.selectedUseCase.id}`, {
        usecase: state.selectedUseCase,
      });
    },

    async updateUseCaseName({ commit, getters }, { useCaseId, name }) {
      commit('setUseCaseName', { useCaseId, name });

      await Http.put(`/usecases/${useCaseId}`, {
        usecase: getters.getUseCaseById(useCaseId),
      });
    },

  },
  getters: {
    getRecentUseCase(state) {
      if (state.useCases.length === 0) return null;
      return state.useCases[0];
    },
    getUseCaseById(state) {
      return (useCaseId) => state.useCases.find((item) => item.id === useCaseId);
    },
  },
};
export default usecase;
