import Http from '@/services/http';

const packet = {
  namespaced: true,
  state: () => ({
    packets: [],
    selectedPacket: null,
    searchResults: [],
    selectedPacketTemplate: null,
  }),
  mutations: {
    setPackets(state, packets) {
      state.packets = packets;
    },
    setSelectedPacket(state, selectedPacket) {
      state.selectedPacket = selectedPacket;
    },
    setSelectedPacketTemplate(state, template) {
      state.selectedPacketTemplate = template;
    },
    addPacket(state, newPacket) {
      state.packets.push(newPacket);
    },
    removePacket(state, packetId) {
      const index = state.packets.map((item) => item.id).indexOf(packetId);
      state.packets.splice(index, 1);
    },
    setSearchResults(state, packets) {
      state.searchResults = packets;
    },
  },
  actions: {
    async fetchPackets({ commit }) {
      const response = await Http.get('/packets');
      commit('setPackets', response.data.packets);
    },
    async createPacket({ commit }, newPacket) {
      const response = await Http.post('/packets', {
        packet: {
          name: newPacket.name,
          layers: newPacket.layers,
        },
      });
      commit('addPacket', response.data.packet);
    },
    // eslint-disable-next-line no-unused-vars
    async createPacketTemplate({ commit }, newPacket) {
      await Http.post('/packet_templates', {
        packet: {
          name: newPacket.name,
          layers: newPacket.layers,
        },
      });
    },
    async deletePacket({ commit }, packetId) {
      await Http.delete(`/packets/${packetId}`);
      commit('removePacket', packetId);
    },
    // eslint-disable-next-line no-unused-vars
    updatePacket({ commit }) {},
    selectPacket({ commit }, selectedPacket) {
      commit('setSelectedPacket', selectedPacket);
    },
    selectPacketTemplate({ commit }, template) {
      commit('setSelectedPacketTemplate', template);
    },
    async searchTemplates({ commit }, searchText) {
      const response = await Http.get(`/packet_templates?q=${searchText}`);
      commit('setSearchResults', response.data.packets);
    },
  },
  getters: {

  },
};
export default packet;
