<template>
  <v-container fluid class="fill-height pa-0 ma-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="pa-0 ma-0">
        <app-bar :section="section" :selected-platform="selectedPlatform"
                 :products="products" :selected-product="selectedProduct" />
      </v-col>
    </v-row>
    <v-row v-if="selectedProduct !== null && selectedUseCase !== null" class="pa-0 ma-0">
      <v-col cols="2" class="pa-0 ma-0 border">
        <v-tabs v-model="tab" center-active>
          <v-tab href="#code" @click="selectSection('project')">code</v-tab>
          <v-tab href="#execute" @click="selectSection('usecase')">execute</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="selectedProduct !== null" class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <coding v-if="section === 'project'" />
        <execute v-if="section === 'usecase'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppBar from '@/components/AppBar.vue';
import { mapState } from 'vuex';
import Coding from '../components/Coding.vue';
import Execute from '../components/Execute.vue';

export default {
  name: 'Home',
  components: {
    AppBar,
    Coding,
    Execute,
  },
  data() {
    return {
      section: 'project',
      tab: null,
    };
  },
  computed: {
    ...mapState({
      selectedPlatform: (state) => state.platform.selectedPlatform,
      products: (state) => state.product.products,
      selectedProduct: (state) => state.product.selectedProduct,
      useCases: (state) => state.usecase.useCases,
      selectedUseCase: (state) => state.usecase.selectedUseCase,
    }),
  },
  methods: {
    selectSection(section) {
      this.section = section;
    },
    updateUseCases() {
      this.$store.dispatch('product/fetchProductTables');
      this.$store.dispatch('product/fetchFileContents', this.selectedProduct.files[0]);
      this.$store.dispatch('usecase/fetchUseCases', this.selectedProduct.id).then(() => {
        if (this.useCases.length === 0) {
          this.$store.dispatch('usecase/createUseCase', {
            name: 'Unnamed Usecase',
            description: '',
            productId: this.selectedProduct.id,
          });
        } else {
          this.$store.commit(
            'usecase/setSelectedUseCase', this.$store.getters['usecase/getRecentUseCase'],
          );
        }
      });
    },
  },
  mounted() {
    // products & use cases
    this.$store.dispatch('product/fetchProducts', this.selectedPlatform.id).then(() => {
      if (this.products.length === 0) {
        this.$store.dispatch('product/createProduct', {
          name: 'Unnamed Product',
          version: '16',
          platformId: this.selectedPlatform.id,
        }).then(() => {
          this.updateUseCases();
        });
      } else if (this.selectedProduct !== null) {
        this.updateUseCases();
      } else {
        this.$store.commit(
          'product/setSelectedProduct', this.$store.getters['product/getRecentProduct'],
        );
        this.updateUseCases();
      }
    });

    // layers, packets, streams
    this.$store.dispatch('layer/fetchLayers');
    this.$store.dispatch('layer/fetchLayerDescription');
    this.$store.dispatch('packet/fetchPackets');
    this.$store.dispatch('stream/fetchStreams');
  },
};
</script>
