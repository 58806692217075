<template>
  <v-tooltip top :disabled="!showTooltip" color="black" :open-delay="500">
    <template v-slot:activator="{ on }">
      <span
        :class="{ selected: isSelectedLayer() }"
        class="layer overline"
        :style="{ 'background-color': layerColor }"
        @click="selectLayer()"
        v-on="on"
      >
        {{ layer.name }}
      </span>
    </template>
    <div>
      <div class="subtitle-2 text-center" :style="{ color: layerColor }">
        {{ layer.name }}
      </div>
      <table>
        <tr v-for="(field, i) in layer.layerFields" :key="i">
          <td class="body-2">{{ field.name }}</td>
          <td>:</td>
          <td class="body-2">
            {{ field.value }}
          </td>
        </tr>
      </table>
    </div>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    selectable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    layer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      color: 'green',
      selected: false,
    };
  },
  computed: {
    ...mapState('layer', ['layerDescription']),
    layerColor() {
      if (!this.layerDescription[this.layer.name]) return '';
      return this.layerDescription[this.layer.name].color;
    },
  },
  methods: {
    selectLayer() {
      if (this.selectable) {
        this.$store.dispatch('layer/selectLayer', this.layer);
      }
    },

    isSelectedLayer() {
      if (!this.selectable) return false;
      return (
        this.$store.state.layer.selectedLayer
        && this.$store.state.layer.selectedLayer.name === this.layer.name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.layer {
  cursor: pointer;
  text-align: center;
  padding: 5px;
  border: 2px solid black;
  margin: 1px;
}
.selected {
  border: 3px solid #5d576b;
}
.overline {
  line-height: 1rem;
}
</style>
