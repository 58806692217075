<template>
  <v-app-bar app>
    <div class='d-flex align-center'>
      <span class='green--text text--darken-4'>P4CLOUD</span>.IO
    </div>
    <template v-if='showOptions'>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <v-divider vertical></v-divider>
      <v-breadcrumbs :items="items"></v-breadcrumbs>
      <ul class="v-breadcrumbs theme--light pl-0" v-if="section === 'usecase'">
        <li class="v-breadcrumbs__divider">/</li>
        <li>
          <div class="v-breadcrumbs__item v-breadcrumbs__item--disabled">
            {{ selectedProduct.name.toUpperCase() }}
          </div>
        </li>
      </ul>

      &nbsp;&nbsp;&nbsp;&nbsp;
      <v-divider vertical></v-divider>

      <!-- Left side contents -->
      <template v-if="selectedProduct !== null && selectedUseCase !== null">

        <!-- current product -->
        <v-select
          v-if="!isEditingName"
          v-model="selectedProduct"
          :items="products"
          class='ml-4 current-select'
          label='Current Project'
          item-text='name'
          item-value='id'
          dense
          hide-details='true'
          @change="onProductChange()"
        ></v-select>
        <v-text-field
          v-if="isEditingName && section === 'project'"
          v-model="productName"
          label="Current Project"
          hide-details="true"
          class="ml-4"
        ></v-text-field>
        <v-btn
          v-if="isEditingName && section === 'project'"
          icon
          @click="updateProductName()"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <!-- current usecase -->
        <v-select
          v-if="isEditingName === false && section === 'usecase'"
          v-model="selectedUseCase"
          :items="useCases"
          class='ml-4 current-select'
          label='Current UseCase'
          item-text='name'
          item-value='id'
          dense
          hide-details='true'
          @change="onUseCaseChange()"
        ></v-select>
        <v-text-field
          v-if="isEditingName && section === 'usecase'"
          v-model="useCaseName"
          label="Current UseCase"
          hide-details="true"
          class="ml-4"
        ></v-text-field>
        <v-btn
          v-if="isEditingName && section === 'usecase'"
          icon
          @click="updateUseCaseName()"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn v-if="!isEditingName" icon @click="isEditingName = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        &nbsp;&nbsp;&nbsp;&nbsp;
        <v-divider vertical></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn v-bind='attrs' icon v-on='on' class='ml-2' color='green' @click='create()'>
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </template>
          <span
          >Create New <span class='text-capitalize'>{{ section }}</span></span
          >
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn v-bind='attrs' icon v-on='on' color='blue' @click='duplicate()'>
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span
          >Duplicate Current <span class='text-capitalize'>{{ section }}</span></span
          >
        </v-tooltip>
        <v-tooltip v-if="section === 'project'" bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn v-bind='attrs' icon v-on='on' color='blue' @click="shareProductDialog = true">
              <v-icon>mdi-share</v-icon>
            </v-btn>
          </template>
          <span
          >Share Current <span class='text-capitalize'>{{ section }}</span></span
          >
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn v-bind='attrs' icon v-on='on' color='red' @click='deleteDialog=true'>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span
          >Delete Current <span class='text-capitalize'>{{ section }}</span></span
          >
        </v-tooltip>

        &nbsp;&nbsp;
        <v-divider vertical></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn v-bind='attrs' icon v-on='on' class='ml-2' @click='showAll()'>
              <v-icon>mdi-dots-grid</v-icon>
            </v-btn>
          </template>
          <span
          >Show All <span class='text-capitalize'>Projects</span></span
          >
        </v-tooltip>
      </template>
    </template>
    <template v-if="selectedProduct === null && showOptions">
      <v-btn
        class="ml-5"
        tile
        small
        color="success"
        @click="createNewProduct()"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        CREATE NEW PRODUCT
      </v-btn>
    </template>
    <template v-if="selectedUseCase === null && showOptions">
      <v-btn
        class="ml-5"
        tile
        small
        color="success"
        @click="createNewUseCase()"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        CREATE NEW USECASE
      </v-btn>
    </template>

    <!-- Right side contents -->
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator='{ on }'>
        <v-btn href='#' text v-on='on'>
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click='accountSettings()'>
          <v-list-item-icon class='mr-2'>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Account Settings</v-list-item-title>
        </v-list-item>
        <v-list-item @click='logout()'>
          <v-list-item-icon class='mr-2'>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete {{ section }}</v-card-title>
        <v-card-text
        >Do you want to delete the current {{ section }} permanently?
        </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="remove()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shareProductDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Share Project </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            label="Email Address"
            type="email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="shareProductDialog = false">
            CANCEL
          </v-btn>
          <v-btn color="blue darken-1" text @click="shareProduct()">
            SHARE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    showOptions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // product or usecase - code or execute
    section: {
      type: String,
      default() {
        return 'project';
      },
    },
  },
  data() {
    return {
      items: [
        {
          text: 'PLATFORMS',
          disabled: false,
          href: '/#/',
        },
        {
          text: 'P4 BMv2',
          disabled: true,
        },
      ],
      deleteDialog: false,
      isEditingName: false,
      shareProductDialog: false,
      email: '',
    };
  },
  computed: {
    ...mapState({
      selectedPlatform: (state) => state.platform.selectedPlatform,
      products: (state) => state.product.products,
      useCases: (state) => state.usecase.useCases,
    }),
    selectedProduct: {
      get() {
        return this.$store.state.product.selectedProduct;
      },
      set(value) {
        this.$store.commit('product/setSelectedProductById', value);
      },
    },
    selectedUseCase: {
      get() {
        return this.$store.state.usecase.selectedUseCase;
      },
      set(value) {
        this.$store.commit('usecase/setSelectedUseCaseById', value);
      },
    },
    productName: {
      get() {
        return this.$store.state.product.selectedProduct.name;
      },
      set(value) {
        this.$store.commit('product/setProductName', {
          productId: this.selectedProduct.id,
          newName: value,
        });
      },
    },
    useCaseName: {
      get() {
        return this.$store.state.usecase.selectedUseCase.name || '';
      },
      set(value) {
        this.$store.commit('usecase/setUseCaseName', {
          useCaseId: this.selectedUseCase.id,
          name: value,
        });
      },
    },
  },
  methods: {
    create() {
      if (this.section === 'project') {
        this.createNewProduct();
      } else {
        this.createNewUseCase();
      }
    },
    remove() {
      if (this.section === 'project') {
        this.deleteCurrentProduct();
      } else {
        this.deleteCurrentUseCase();
      }
    },
    duplicate() {
      if (this.section === 'project') {
        this.duplicateCurrentProduct();
      } else {
        this.duplicateCurrentUseCase();
      }
    },
    showAll() {
      this.$router.push('/products');
    },
    createNewProduct() {
      this.$store.dispatch('product/createProduct', {
        name: 'Unnamed Product',
        version: '16',
        platformId: this.selectedPlatform.id,
      }).then(() => this.updateUseCases());
    },
    createNewUseCase() {
      this.$store.dispatch('usecase/createUseCase', {
        name: 'Unnamed Usecase',
        description: '',
        productId: this.selectedProduct.id,
      });
    },
    deleteCurrentProduct() {
      this.$store
        .dispatch('product/deleteProduct', this.selectedProduct.id)
        .then(() => {
          this.$store.dispatch(
            'product/selectProduct',
            this.$store.getters['product/getRecentProduct'],
          ).then(() => this.updateUseCases());
          this.deleteDialog = false;
        });
    },
    deleteCurrentUseCase() {
      this.$store
        .dispatch('usecase/deleteUseCase', this.selectedUseCase.id)
        .then(() => {
          this.$store.dispatch('usecase/selectUseCase', this.$store.getters['usecase/getRecentUseCase']);
          this.deleteDialog = false;
        });
    },
    duplicateCurrentProduct() {
      this.$store.dispatch('product/duplicateProduct', this.selectedProduct.id)
        .then(() => this.updateUseCases());
    },
    duplicateCurrentUseCase() {
      this.$store.dispatch('usecase/duplicateUseCase', this.selectedUseCase.id);
    },
    shareProduct() {
      this.$store
        .dispatch('product/shareProduct', {
          email: this.email,
          productId: this.selectedProduct.id,
        })
        .then(() => {
          this.shareProductDialog = false;
          this.email = '';
        });
    },
    accountSettings() {
      console.log('account settings');
    },
    logout() {
      localStorage.removeItem('currentUser');
      this.$router.push('/login');
    },
    updateUseCases() {
      this.$store.dispatch('product/fetchProductTables');
      this.$store.dispatch('product/fetchFileContents', this.selectedProduct.files[0]);
      this.$store.dispatch('usecase/fetchUseCases', this.selectedProduct.id).then(() => {
        if (this.useCases.length === 0) {
          this.$store.dispatch('usecase/createUseCase', {
            name: 'Unnamed Usecase',
            description: '',
            productId: this.selectedProduct.id,
          });
        } else {
          this.$store.commit(
            'usecase/setSelectedUseCase', this.$store.getters['usecase/getRecentUseCase'],
          );
        }
      });
    },
    onProductChange() {
      this.updateUseCases();
    },
    onUseCaseChange() {
      this.$store.commit('usecase/setUseCaseResults', null);
    },
    updateProductName() {
      this.$store.dispatch('product/updateProductName', {
        productId: this.selectedProduct.id,
        newName: this.selectedProduct.name,
      });
      this.isEditingName = false;
    },
    updateUseCaseName() {
      this.$store.dispatch('usecase/updateUseCaseName', {
        useCaseId: this.selectedUseCase.id,
        name: this.selectedUseCase.name,
      });
      this.isEditingName = false;
    },
  },
};
</script>
<style lang='sass'>
.current-select
  max-width: 300px !important
</style>
