<template>
  <v-row class="pa-2">
    <v-col cols="12">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-subheader class="ma-0 pa-0">Description</v-subheader>
          <v-textarea id='use-case-description' auto-grow v-model="useCaseDescription"></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  computed: {
    ...mapState({
      selectedUseCase: (state) => state.usecase.selectedUseCase,
    }),
    useCaseDescription: {
      get() {
        return this.$store.state.usecase.selectedUseCase.description;
      },
      set(value) {
        this.$store.dispatch('usecase/updateUseCaseDescription', {
          useCaseId: this.selectedUseCase.id,
          description: value,
        });
      },
    },
  },
};
</script>

<style lang="sass" scoped>
#use-case-description
  overflow: scroll
  overflow-x: hidden
  max-height: 600px
</style>
