<template>
  <span class="mr-3">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on">mdi-file</v-icon>
      </template>

      <v-card>
        <v-card-title class="pa-1 subtitle-2">
          <span>File Name</span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-1">
          <v-text-field v-model="name" label="Name" required></v-text-field>
        </v-card-text>

        <v-card-actions class="pa-1">
          <v-spacer></v-spacer>
          <v-btn small class="primary" text @click="closeMenu">Close</v-btn>
          <v-btn small class="primary" text @click="createFile">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </span>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
      name: '',
    };
  },
  methods: {
    closeMenu() {
      this.menu = false;
      this.name = '';
    },
    createFile() {
      this.$store.dispatch('product/createFile', this.name).then(() => {
        this.name = '';
        this.menu = false;
      });
    },
  },
};
</script>
