import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import platform from '@/store/platform';
import product from '@/store/product';
import usecase from '@/store/usecase';
import packet from '@/store/packet';
import stream from '@/store/stream';
import layer from '@/store/layer';

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: [
    'platform.selectedPlatform',
    'product.selectedProduct',
  ],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    platform,
    product,
    usecase,
    layer,
    packet,
    stream,
  },
  getters: {
    // eslint-disable-next-line no-unused-vars
    loggedInUser(state) {
      return JSON.parse(localStorage.getItem('currentUser')) || '';
    },
  },
  plugins: [dataState],
});
