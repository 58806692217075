<template>
  <div>
    <template v-for="(stream, i) in streams">
      <v-list-item :key="stream.id" dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-list-item-subtitle>
              {{ stream.name }}
            </v-list-item-subtitle>
            <StreamListItem
              :stream="stream"
              :can-delete="canDelete"
              :show-tooltip="showTooltip"
            ></StreamListItem>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <StreamDeleteButton :stream-id="stream.id"></StreamDeleteButton>
        </v-list-item-action>
      </v-list-item>

      <v-divider v-if="i + 1 < streams.length" :key="i"></v-divider>
    </template>
  </div>
</template>

<script>
import StreamListItem from '@/components/StreamListItem.vue';
import StreamDeleteButton from '@/components/StreamDeleteButton.vue';

export default {
  components: {
    StreamDeleteButton,
    StreamListItem,
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    streams: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
