<template>
  <splitpanes class="default-theme">
    <pane size="15" min-size="10" max-size="30">
      <div class="pa-2 side-panel">
        <file-explorer />
      </div>
    </pane>
    <pane>
      <div class="pa-1 main-panel">
        <v-tabs>
          <v-tab>{{ selectedFile }}</v-tab>
        </v-tabs>
        <code-editor />
      </div>
    </pane>
  </splitpanes>
</template>
<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { mapState } from 'vuex';
import FileExplorer from './FileExplorer.vue';
import CodeEditor from './CodeEditor.vue';

export default {
  components: {
    Splitpanes,
    Pane,
    FileExplorer,
    CodeEditor,
  },
  computed: {
    ...mapState({
      selectedFile: (state) => state.product.selectedFile,
    }),
  },
};
</script>
<style lang="sass" scoped>
.side-panel,
.main-panel
  background-color: #ffffff
  display: flex
  flex-direction: column
  justify-content: flex-start
  min-height: calc(100vh - 64px - 48px)
  max-height: calc(100vh - 64px -  48px)
  overflow-y: auto
  overflow-x: hidden
  border: 1px solid #E0E0E0
</style>
