<template>
  <div>
    <v-row class="ma-0">
      <v-text-field
        v-model="newStream.name"
        label="Name"
        required
      ></v-text-field>
    </v-row>
    <v-row class="ma-0">
      <v-text-field
        v-model="newStream.count"
        label="Loop Count"
        required
      ></v-text-field>
    </v-row>

    <v-row class="ma-0">
      <div class="pa-4 build-area">
        <v-list>
          <v-list-item v-for="(packet, i) of newStream.packets" :key="i" dense>
            <v-list-item-content>
              <PacketListItem
                :packet="packet"
                :show-tooltip="true"
              ></PacketListItem>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn x-small dark fab color="primary" @click="showMenu($event)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row class="ma-0">
      <v-btn small class="primary ma-1" text @click="reset">reset</v-btn>
      <v-btn small class="primary ma-1" text @click="createNewStream()"
      >Save</v-btn
      >
      <v-btn
        v-if="loggedInUser.role === 1"
        small
        class="primary ma-1"
        text
        @click="createNewStreamTemplate"
      >Save As Template</v-btn
      >
    </v-row>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card>
        <v-card-title
        >Available Packets({{ filteredPackets.length }})</v-card-title
        >
        <v-divider></v-divider>
        <v-text-field
          v-model="searchPacket"
          label="Search Packets"
          required
          class="ma-2"
          clearable
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-text
          style="height: 300px; overflow: scroll; overflow-x: hidden"
        >
          <v-list-item v-for="packet in filteredPackets" :key="packet.id" dense>
            <v-list-item-content @click="addPacket(packet)">
              <v-list-item-title>
                <v-list-item-subtitle>
                  {{ packet.name }}
                </v-list-item-subtitle>
                <PacketListItem :packet="packet"></PacketListItem>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PacketListItem from '@/components/PacketListItem.vue';

export default {
  components: { PacketListItem },
  data: () => ({
    menu: false,
    newStream: {
      name: '',
      packets: [],
      count: 1,
    },
    x: 0,
    y: 0,
    searchPacket: '',
  }),
  computed: {
    ...mapGetters(['loggedInUser']),
    ...mapState({
      packets: (state) => state.packet.packets,
      selectedStreamTemplate: (state) => state.stream.selectedStreamTemplate,
    }),
    filteredPackets() {
      return this.packets.filter((packet) => packet.name
        .toLowerCase()
        .includes(this.searchPacket.toLowerCase()));
    },
  },
  watch: {
    selectedStreamTemplate() {
      this.newStream = JSON.parse(JSON.stringify(this.selectedStreamTemplate));
    },
  },
  created() {},
  methods: {
    addPacket(packet) {
      this.newStream.packets.push(packet);
      this.mneu = false;
    },
    createNewStream() {
      this.$store.dispatch('stream/createStream', this.newStream);
      this.reset();
    },
    showMenu(e) {
      this.menu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menu = true;
      });
    },
    createNewStreamTemplate() {
      this.$store.dispatch('stream/createStreamTemplate', this.newStream);
      this.reset();
    },
    reset() {
      this.newStream.name = '';
      this.newStream.packets = [];
      this.newStream.count = 1;
    },
  },
};
</script>

<style scoped>
.build-area {
  border: 1px dotted black;
  width: 100%;
  text-align: center;
}
</style>
