<template>
    <v-card :loading="isUseCaseRunning">
      <div class="subtitle-2 pa-1 ml-1">
        <template>
          <div class="d-flex justify-space-between align-center">
            <span> TRAFFIC {{ port.id }} </span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon small class="ma-1" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showPacketMenu($event)">
                  <v-list-item-title>Add Packet</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showStreamMenu($event)">
                  <v-list-item-title>Add Stream</v-list-item-title>
                </v-list-item>
                <v-list-item @click="removePortFromUseCase()">
                  <v-list-item-title>Remove Port</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>
      <v-divider></v-divider>
      <v-card-text
        v-if="showContents"
        id="port-content"
        class="pa-1 scrollable-content"
      >
        <v-tabs>
          <v-tab> Packets </v-tab>
          <v-tab> Streams </v-tab>

          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-1">
                <v-list-item
                  v-for="(packet, i) in port.packets"
                  :key="i"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-list-item-subtitle>
                        {{ packet.name }}
                      </v-list-item-subtitle>
                      <PacketListItem
                        :packet="packet"
                        :show-tooltip="true"
                      ></PacketListItem>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="deletePacket(packet.id)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-list-item
                  v-for="(stream, i) in port.streams"
                  :key="i"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <StreamListItem
                        :stream="stream"
                        :show-tooltip="true"
                      ></StreamListItem>
                      <v-list-item-subtitle>
                        {{ stream.name }}
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="deleteStream(stream.id)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-divider v-if="showContents"></v-divider>
      <v-card-text class="pa-2 overline">
        <v-chip label class="purple lighten-4 pa-1" small>
          Pkts Sent :
          <span class="pl-1">
                <animated-number
                  :value="getPortResults.txd"
                  :duration="500"
                  :round="true"
                /></span>
        </v-chip>
        &nbsp;
        <v-chip label class="cyan lighten-4 pa-1" small>
          Pkts Rcvd :
          <span class="pl-1">
                <animated-number
                  :value="getPortResults.rxd"
                  :duration="500"
                  :round="true"
                /></span>
        </v-chip>
      </v-card-text>
      <PacketMenuDialog @addPacket="addPacket" :packet-menu="packetMenu" :x="x" :y="y" />
      <StreamMenuDialog @addStream="addStream" :stream-menu="streamMenu" :x="x" :y="y" />
    </v-card>
</template>
<script>
import { mapState } from 'vuex';
import AnimatedNumber from 'animated-number-vue';
import StreamMenuDialog from '@/components/StreamMenuDialog.vue';
import PacketMenuDialog from '@/components/PacketMenuDialog.vue';
import StreamListItem from '@/components/StreamListItem.vue';
import PacketListItem from '@/components/PacketListItem.vue';

export default {
  components: {
    PacketMenuDialog, StreamMenuDialog, AnimatedNumber, PacketListItem, StreamListItem,
  },
  props: {
    port: {
      type: Object,
      default() {
        return {};
      },
    },
    showContents: {
      type: Boolean,
      default: true,
    },
    pos: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      streamMenu: false,
      packetMenu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState({
      packets: (state) => state.packet.packets,
      streams: (state) => state.stream.streams,
      isUseCaseRunning: (state) => state.usecase.isUseCaseRunning,
      results: (state) => state.usecase.selectedUseCaseResults,
    }),
    getPortResults() {
      if (!this.results) {
        return {
          txd: '0',
          rxd: '0',
        };
      }

      const index = this.results
        .map((item) => item.portId)
        .indexOf(this.port.id);
      if (index === -1) return { txd: '0', rxd: '0' };

      return {
        txd: this.results[index].packetsTxd.toString(),
        rxd: this.results[index].packetsRxd.toString(),
      };
    },
  },
  methods: {
    showPacketMenu(e) {
      this.packetMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.packetMenu = true;
      });
    },
    showStreamMenu(e) {
      this.streamMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.streamMenu = true;
      });
    },
    removePortFromUseCase() {
      this.$store.dispatch('usecase/removePortFromUseCase', this.port.id);
    },
    addPacket(packet) {
      const newPacket = JSON.parse(JSON.stringify(packet));
      newPacket.id = this.port.packets.length + 1;
      this.$store.dispatch('usecase/addPacket', {
        portId: this.port.id,
        newPacket,
      });
      this.packetMenu = false;
    },
    deletePacket(packetId) {
      this.$store.dispatch('usecase/deletePacket', {
        portId: this.port.id,
        packetId,
      });
    },
    addStream(stream) {
      const newStream = JSON.parse(JSON.stringify(stream));
      newStream.id = this.port.streams.length + 1;
      this.$store.dispatch('usecase/addStream', {
        portId: this.port.id,
        newStream,
      });
      this.streamMenu = false;
    },
    deleteStream(streamId) {
      this.$store.dispatch('usecase/deleteStream', {
        portId: this.port.id,
        streamId,
      });
    },
  },
};
</script>
<style scoped lang="sass">
.scrollable-content
  overflow: scroll
  overflow-x: hidden
  overflow-y: auto
#port-content
  max-height: 200px
</style>
