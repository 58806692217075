<template>
  <div>
    <v-list-item v-for="(packet, i) in packets" :key="i" dense>
      <v-list-item-content>
        <v-list-item-title>
          <v-list-item-subtitle v-if="showTitle">
            {{ packet.name }}
          </v-list-item-subtitle>
          <PacketListItem
            :packet="packet"
            :can-delete="canDelete"
            :show-tooltip="showTooltip"
          ></PacketListItem>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <PacketDeleteButton
          v-if="canDelete"
          :packet-id="packet.id"
        ></PacketDeleteButton>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import PacketListItem from '@/components/PacketListItem.vue';
import PacketDeleteButton from '@/components/PacketDeleteButton.vue';

export default {
  components: {
    PacketDeleteButton,
    PacketListItem,
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    packets: {
      type: Array,
      default() {
        return [];
      },
    },
    showTitle: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
