<template>
  <v-row class="ma-0">
    <v-col>
      <v-row>
        <v-text-field
          v-model="searchText"
          dense
          clearable
          placeholder="Search Packet Templates"
          append-icon="mdi-search-web"
          outlined
          @keydown.enter="search"
          @click:clear="clearResults"
        ></v-text-field>
      </v-row>
      <v-row v-if="searchText">
        <v-col>
          <div class="d-flex flex-wrap ma-1">
            <v-card
              v-for="packet in searchResults"
              :key="packet.id"
              rounded
              class="pa-1 ma-1 overline"
              outlined
              tile
              style="border: 1px solid black"
              @click="selectPacketTemplate(packet)"
            >
              {{ packet.name }}
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      searchText: '',
    };
  },
  computed: {
    ...mapState({
      searchResults: (state) => state.packet.searchResults,
    }),
  },
  methods: {
    search() {
      if (!this.searchText || this.searchText === '') {
        return;
      }
      this.$store.dispatch('packet/searchTemplates', this.searchText);
    },
    selectPacketTemplate(template) {
      this.$store.dispatch('packet/selectPacketTemplate', template);
    },
    clearResults() {
      this.searchText = null;
      this.$store.commit('packet/setSearchResults', null);
    },
  },
};
</script>

<style scoped></style>
