<template>
  <div v-if="selectedLayer">
    <form>
      <table>
        <tr v-for="(field, i) in selectedLayer.layerFields" :key="i">
          <td>
            <v-text-field
              dense
              outlined
              :label="field.name"
              :value="field.value"
              @input="save(field.name, $event)"
            ></v-text-field>
          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
export default {
  computed: {
    selectedLayer() {
      return this.$store.state.layer.selectedLayer;
    },
  },
  methods: {
    save(name, value) {
      this.$store.dispatch('layer/updateLayerField', { name, value });
    },
  },
};
</script>

<style lang="scss" scoped></style>
