import Http from '@/services/http';

const platform = {
  namespaced: true,
  state: () => ({
    platforms: [],
    selectedPlatform: null,
  }),
  mutations: {
    setPlatforms(state, platforms) {
      state.platforms = platforms;
    },
    setSelectedPlatform(state, selectedPlatform) {
      state.selectedPlatform = selectedPlatform;
    },
  },
  actions: {
    async fetchPlatforms({ commit }) {
      try {
        const resp = await Http.get('/platforms');
        commit('setPlatforms', resp.data.platforms);
      } catch (e) {
        console.log(e);
      }
    },
    async selectPlatform({ commit }, selectedPlatform) {
      commit('setSelectedPlatform', selectedPlatform);
      await Http.post(`/platforms/${selectedPlatform.id}/start`);
    },
  },
  getters: {},
};
export default platform;
