<template>
  <v-simple-table v-if="selectedUseCase">
    <thead>
    <tr>
      <th>MCAST GROUP ID</th>
      <th>MCAST PORT LIST</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        <label>
          <v-text-field v-model="mcastGroupId" required></v-text-field>
        </label>
      </td>
      <td>
        <v-select
          v-model="selectedPorts"
          :chips="true"
          :multiple="true"
          :items="useCasePorts"
        ></v-select>
      </td>
      <td>
        <v-btn tile @click="saveMulticastGroupEntry()">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedPorts: [],
      mcastGroupId: null,
    };
  },
  computed: {
    ...mapState({
      selectedUseCase: (state) => state.usecase.selectedUseCase,
    }),
    useCasePorts() {
      if (!this.selectedUseCase) return [];

      return this.selectedUseCase.ports.map((item) => item.id);
    },
  },
  methods: {
    saveMulticastGroupEntry() {
      const entry = {
        multicast_group_id: this.mcastGroupId,
        replicas: [],
      };

      this.selectedPorts.forEach((port) => {
        entry.replicas.push({
          egress_port: port,
          instance: this.mcastGroupId,
        });
      });

      this.$store.dispatch('usecase/addMulticastGroupEntry', entry);
    },
  },
};
</script>
