<template>
    <v-row class="ma-0 pa-0 d-flex flex-column">
      <v-col cols="12" class="ma-0 pa-0">
        <div class="d-flex justify-space-between">
          <span class="overline mb-0">explorer</span>
          <span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <CreateFileButton />
                </div>
              </template>
              <span>New File</span>
            </v-tooltip>
          </span>
        </div>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0">
        <v-card-text class="scrollable-content file-explorer pa-0">
          <v-list dense flat class="ma-0 pa-0">
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="file in sortedFiles"
                :key="file"
                class="pa-0"
                style="max-height: 30px"
              >
                <v-list-item-icon class="mr-1">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pa-1">
                  <v-list-item-title
                    style="cursor: pointer"
                    @click="selectFile(file)"
                  >{{ file
                    }}<sup v-if="selectedProduct.mainFile === file"
                    >*</sup
                    ></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small class="ma-1" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="markMainFile(file)">
                        <v-list-item-title
                        >Mark as main file</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="deleteFile(file)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
</template>
<script>
import { mapState } from 'vuex';
import CreateFileButton from '@/components/CreateFileButton.vue';

export default {
  components: {
    CreateFileButton,
  },
  data: () => ({
    selectedItem: 'pipeline.p4',
  }),
  computed: {
    ...mapState({
      selectedProduct: (state) => state.product.selectedProduct,
    }),
    sortedFiles() {
      return this.selectedProduct.files.map((file) => file).sort((a, b) => a.localeCompare(b));
    },
  },
  watch: {
    selectedProduct() {
      if (this.selectedProduct.files.length > 0) {
        this.selectFile(this.selectedProduct.files[0]);
        // eslint-disable-next-line prefer-destructuring
        this.selectedItem = this.selectedProduct.files[0];
      }
    },
  },
  methods: {
    selectFile(file) {
      this.selectedItem = file;
      this.$store.dispatch('product/fetchFileContents', file);
    },
    markMainFile(file) {
      this.$store.dispatch('product/markMainFile', file);
    },
    deleteFile(file) {
      this.$store.dispatch('product/deleteFile', file);
    },
  },
};
</script>
<style lang="sass" scoped>
#file-tree
    min-height: calc(100vh - 64px - 48px - 50px)
    max-height: calc(100vh - 64px -  48px - 50px)
    overflow-y: auto
    overflow-x: hidden
</style>
