import Http from '@/services/http';

const product = {
  namespaced: true,
  state: () => ({
    products: [],
    selectedProduct: null,
    selectedFile: null,
    selectedFileContents: '',
    compileOutput: '',
    selectedTable: null,
    selectedTableType: null,
    pipelineInfo: null,
  }),
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setSelectedProduct(state, selectedProduct) {
      state.selectedProduct = selectedProduct;
    },
    addProduct(state, newProduct) {
      state.products.push(newProduct);
    },
    setSelectedProductById(state, productId) {
      const index = state.products.findIndex((item) => item.id === productId);
      state.selectedProduct = state.products[index];
    },
    removeProduct(state, productId) {
      const index = state.products.findIndex((item) => item.id === productId);
      state.products.splice(index, 1);
    },
    setSelectedFile(state, fileName) {
      state.selectedFile = fileName;
    },
    setSelectedFileContents(state, contents) {
      state.selectedFileContents = contents;
    },
    setCompileOutput(state, value) {
      state.compileOutput = value;
    },
    setProductTables(state, info) {
      state.pipelineInfo = info;
    },
    setSelectedTable(state, table) {
      state.selectedTable = table;
    },
    setSelectedTableType(state, type) {
      state.selectedTableType = type;
    },
    setProductName(state, { productId, newName }) {
      const index = state.products.findIndex((item) => item.id === productId);
      state.products[index].name = newName;
    },
    setMainFile(state, file) {
      state.selectedProduct.mainFile = file;
    },
    addFile(state, fileName) {
      state.selectedProduct.files.push(fileName);
    },
    removeFile(state, fileName) {
      const index = state.selectedProduct.files.findIndex(
        (item) => item === fileName,
      );
      state.selectedProduct.files.splice(index, 1);
    },
  },
  actions: {
    async fetchProducts({ commit }, platformId) {
      try {
        const resp = await Http.get(`/products?platform_id=${platformId}`);
        commit('setProducts', resp.data.products);
      } catch (e) {
        console.log(e);
      }
    },
    async selectProduct({ commit }, selectedProduct) {
      commit('setSelectedProductById', selectedProduct.id);
    },
    async createProduct({ commit }, newProduct) {
      try {
        const resp = await Http.post('/products', {
          product: newProduct,
        });
        commit('addProduct', resp.data);
        commit('setSelectedProduct', resp.data);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteProduct({ commit }, productId) {
      try {
        await Http.delete(`/products/${productId}`);
        commit('removeProduct', productId);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchSelectedFileContents({ commit, state }, fileName) {
      const response = await Http.get(
        `/products/${state.selectedProduct.id}/files/${fileName}`,
      );
      commit('setSelectedFileContents', response.data.contents ? response.data.contents : ' ');
      commit('setSelectedFile', fileName);
    },
    async saveFile({ commit, state }, contents) {
      commit('setSelectedFileContents', contents);
      await Http.put(
        `/products/${state.selectedProduct.id}/files/${state.selectedFile}`,
        {
          contents: state.selectedFileContents,
        },
      );
    },
    async duplicateProduct({ commit }, productId) {
      const resp = await Http.post(
        `/products/${productId}/duplicate`,
      );
      commit('addProduct', resp.data);
      commit('setSelectedProductById', resp.data.id);
    },
    async compile({ commit, state, rootState }) {
      if (rootState.platform.selectedPlatform.isCompilationAllowed) {
        const response = await Http.post(
          `/products/${state.selectedProduct.id}/compile`,
        );
        commit('setCompileOutput', response.data.detail);
      }
    },
    async fetchProductTables({ commit, state }) {
      try {
        const response = await Http.get(
          `/products/${state.selectedProduct.id}/tables`,
        );
        commit('setProductTables', response.data);
      } catch (e) {
        console.info('error fetching product tables');
        commit('setProductTables', null);
      }
    },
    async updateProductName({ commit }, { productId, newName }) {
      commit('setProductName', { productId, newName });
      await Http.put(`/products/${productId}`, {
        name: newName,
      });
    },
    async fetchFileContents({ commit, state }, fileName) {
      const response = await Http.get(
        `/products/${state.selectedProduct.id}/files/${fileName}`,
      );
      commit('setSelectedFileContents', response.data.contents ? response.data.contents : ' ');
      commit('setSelectedFile', fileName);
    },

    async markMainFile({ commit, state }, fileName) {
      commit('setMainFile', fileName);
      await Http.put(
        `/products/${state.selectedProduct.id}/files/${fileName}/main`,
      );
    },

    async createFile({ commit, state }, fileName) {
      await Http.post(`/products/${state.selectedProduct.id}/files`, {
        name: fileName,
      });
      commit('addFile', fileName);
      commit('setSelectedFile', fileName);
    },

    async deleteFile({ commit, state }, fileName) {
      commit('removeFile', fileName);
      await Http.delete(
        `/products/${state.selectedProduct.id}/files/${fileName}`,
      );
    },

    async shareProduct({ dispatch }, { email, productId }) {
      await Http.post(`/products/${productId}/share`, {
        email,
      });

      dispatch(
        'showNotification',
        {
          message: 'Product shared successfully',
          type: 'success',
        },
        { root: true },
      );
    },
  },
  getters: {
    getRecentProduct(state) {
      if (state.products.length === 0) return null;

      if (state.products.length === 1) {
        return state.products[0];
      }
      return state.products
        .slice()
        .sort(
          (a, b) => new Date(b.last_accessed * 1000) - new Date(a.last_accessed * 1000),
        )[0];
    },
  },
};
export default product;
