<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="true"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-1 pa-0"
        color="primary"
        small
        outlined
        text
        v-bind="attrs"
        v-on="on"
      >
        TABLE
      </v-btn>
    </template>
    <v-card class="pa-1">
      <v-timeline dense>
        <v-timeline-item fill-dot class="white--text mb-8">
          <template v-slot:icon>
            <span>1</span>
          </template>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn primary v-on="on" @click="selectTableType('ingress')">
                INGRESS
              </v-btn>
            </template>
            <v-list v-if="ingressTables.length > 0">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="table in ingressTables"
                  :key="table.name"
                  @click="selectTable(table)"
                >
                  <v-list-item-content>
                    {{ table.name }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-timeline-item>
        <v-timeline-item fill-dot class="white--text mb-8">
          <template v-slot:icon>
            <span>2</span>
          </template>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn primary v-on="on" @click="selectTableType('traffic')">
                TRAFFIC MANAGER
              </v-btn>
            </template>
          </v-menu>
        </v-timeline-item>
        <v-timeline-item fill-dot class="white--text mb-8">
          <template v-slot:icon>
            <span>3</span>
          </template>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn primary v-on="on" @click="selectTableType('egress')">
                EGRESS
              </v-btn>
            </template>
            <v-list v-if="egressTables.length > 0">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="table in egressTables"
                  :key="table.name"
                  @click="selectTable(table)"
                >
                  <v-list-item-content>
                    {{ table.name }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState({
      pipelineInfo: (state) => state.product.pipelineInfo,
    }),
    ingressTables() {
      if (!this.pipelineInfo) return [];
      return this.pipelineInfo.tables.filter((item) => item.name.includes('MyIngress'));
    },
    egressTables() {
      if (!this.pipelineInfo) return [];
      return this.pipelineInfo.tables.filter((item) => item.name.includes('MyEgress'));
    },
  },
  methods: {
    selectTable(table) {
      this.$store.commit('product/setSelectedTable', table);
    },
    selectTableType(type) {
      if (this.pipelineInfo === null) {
        this.$store.dispatch(
          'showNotification',
          {
            message: 'Please compile the product pipeline to view tables',
            timeout: -1,
            type: 'info',
          },
          { root: true },
        );
      } else {
        this.$store.commit('product/setSelectedTableType', type);
      }
    },
  },
};
</script>
