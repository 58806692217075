<template>
  <v-row class="ma-0 pa-0">
    <v-col class="ma-0 pa-0">
      <splitpanes id="edit-split-pane" horizontal class="default-theme">
        <pane size="80" min-size="20" max-size="100">
          <prism-editor
            class="my-editor"
            v-model="code"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </pane>
        <pane>
          <div class="console-wrapper">
            <v-row class="ma-0 pa-0">
              <v-btn
                class="ma-2"
                color="primary"
                x-small
                outlined
                @click="compile"
              >
                compile
              </v-btn>
            </v-row>
            <v-row class="ma-0 pa-1" style="color: white">
              <v-alert v-if="alertType !== ''" dense text :type="alertType">
                <div class="title">{{ compileOutput.message }}</div>
                <div>{{ compileOutput.output }}</div>
              </v-alert>
            </v-row>
          </div>
        </pane>
      </splitpanes>
    </v-col>
  </v-row>
</template>
<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';

import 'prismjs/themes/prism-tomorrow.css';
import { mapState } from 'vuex'; // import syntax highlighting styles

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
  components: {
    PrismEditor,
    Splitpanes,
    Pane,
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.product.selectedProduct,
      compileOutput: (state) => state.product.compileOutput,
    }),
    code: {
      get() {
        return this.$store.state.product.selectedFileContents;
      },
      set(value) {
        this.$store.dispatch('product/saveFile', value);
      },
    },
    alertType() {
      if (this.compileOutput.status_code === 200) {
        return 'success';
      } if (this.compileOutput.status_code === 400) {
        return 'error';
      }
      return '';
    },
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.clike);
    },
    compile() {
      this.$store.dispatch('product/compile').then(() => {
        this.$store.dispatch('product/fetchProductTables');
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.my-editor
    background: #2d2d2d
    color: #ccc

    font-family: 'JetBrains Mono', monospace
    font-size: 16px
    line-height: 1.5
    padding: 5px

.prism-editor__textarea:focus
    outline: none

#edit-split-pane
  height: calc(100vh - 48px - 48px - 78px)
</style>
