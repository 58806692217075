import Http from '@/services/http';

const layer = {
  namespaced: true,
  state: () => ({
    layers: [],
    layerDescription: {},
    selectedLayer: null,
    supportedLayers: [],
  }),
  mutations: {
    setLayers(state, layers) {
      state.layers = layers;
    },
    setSupportedLayers(state, layers) {
      state.supportedLayers = layers;
    },
    setLayerDescription(state, layerDescription) {
      state.layerDescription = layerDescription;
    },
    setSelectedLayer(state, selectedLayer) {
      state.selectedLayer = selectedLayer;
    },
    updateField(state, field) {
      const index = state.selectedLayer.layerFields
        .map((item) => item.name)
        .indexOf(field.name);
      state.selectedLayer.layerFields[index].value = field.value;
    },
  },
  actions: {
    async fetchLayers({ commit }) {
      const response = await Http.get('/layers');
      commit('setLayers', response.data.layers);
    },
    async fetchSupportedLayers({ commit }) {
      const response = await Http.get('/supported_layers');
      commit('setSupportedLayers', response.data);
    },
    async fetchLayerDescription({ commit }) {
      const response = await Http.get('/layer_description');
      commit('setLayerDescription', response.data.layerDescription);
    },
    selectLayer({ commit }, selectedLayer) {
      commit('setSelectedLayer', selectedLayer);
    },
    updateLayerField({ commit }, field) {
      commit('updateField', field);
    },
  },
  getters: {

  },
};
export default layer;
