<template>
  <div>
    <v-card v-if="product" hover class="ma-2" width="200">
      <div class="subtitle-2 pa-1 ml-1">
        <div class="d-flex justify-space-between align-center">
          <v-text-field
            v-if="isEditingName"
            class='pa-0 ma-0'
            :value="product.name"
            @change="updateProductName"
            hide-details="true"
          ></v-text-field>
          <v-icon v-if="isEditingName" small @click="isEditingName = false">mdi-check</v-icon>
          <v-hover v-slot:default="{ hover }">
            <div>
              <span v-if="!isEditingName">{{ truncatedName }}</span>
              <span v-if="hover">
              <v-icon small @click="isEditingName = true">mdi-pencil</v-icon>
            </span>
            </div>
          </v-hover>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon small class="ma-1" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="startProduct()">
                <v-list-item-title>Start Project</v-list-item-title>
              </v-list-item>
              <v-list-item @click="shareProductDialog = true">
                <v-list-item-title>Share Project</v-list-item-title>
              </v-list-item>
              <v-list-item @click="duplicateProduct(product.id)">
                <v-list-item-title>Duplicate</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteProduct(product.id)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-text class="body-2">
        {{ product.useCasesCount }} - USE CASES
      </v-card-text>
    </v-card>
    <v-dialog v-model="shareProductDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Share Project</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            label="Email Address"
            type="email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="shareProductDialog = false">
            CANCEL
          </v-btn>
          <v-btn color="blue darken-1" text @click="shareProduct()">
            SHARE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isEditingName: false,
      shareProductDialog: false,
      email: '',
      length: 15,
    };
  },
  computed: {
    truncatedName() {
      return (
        this.product.name.slice(0, this.length) + (this.product.name.length > this.length ? '...' : '')
      );
    },
  },
  methods: {
    startProduct() {
      this.$store.dispatch('product/selectProduct', this.product)
        .then(() => {
          this.$router.push('/home');
        });
    },

    duplicateProduct() {
      this.$store.dispatch('product/duplicateProduct', this.product.id);
    },

    deleteProduct() {
      this.$store.dispatch('product/deleteProduct', this.product.id);
    },

    updateProductName(newName) {
      this.$store.dispatch('product/updateProductName', {
        productId: this.product.id,
        newName,
      });
    },
    shareProduct() {
      this.$store
        .dispatch('product/shareProduct', {
          email: this.email,
          productId: this.product.id,
        })
        .then(() => {
          this.shareProductDialog = false;
          this.email = '';
        });
    },
  },
};
</script>
