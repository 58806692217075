<template>
  <v-row class="ma-0 fill-height">
    <!-- Selected use case detail -->
    <v-col v-if="selectedUseCase" cols="12" class="pa-0">
      <v-row class="ma-0">
        <v-btn
          class="ma-1 pa-0"
          color="green"
          small
          text
          outlined
          @click="runUseCase()"
        >
          Run
        </v-btn>
        <v-btn
          class="ma-1 pa-0"
          color="primary"
          small
          text
          outlined
          @click="addPort()"
        >
          Add Traffic Port
        </v-btn>
        <TableSelectorButton />
      </v-row>
      <v-row class="ma-0">
        <v-divider></v-divider>
      </v-row>
      <v-row class="ma-0">
        <UseCaseDetails />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import UseCaseDetails from '@/components/UseCaseDetails.vue';
import TableSelectorButton from '@/components/TableSelectorButton.vue';

export default {
  components: {
    UseCaseDetails,
    TableSelectorButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      useCases: (state) => state.usecase.useCases,
      selectedUseCase: (state) => state.usecase.selectedUseCase,
      selectedProduct: (state) => state.product.selectedProduct,
    }),
  },
  methods: {
    addPort() {
      this.$store.dispatch('usecase/addPortToUseCase');
    },
    runUseCase() {
      if (this.selectedProduct.files.length === 0) {
        this.$store.dispatch(
          'showNotification',
          {
            message: 'Create a pipeline before executing the use cases',
            timeout: -1,
            type: 'info',
          },
          { root: true },
        );
      } else {
        this.$store.dispatch('usecase/executeSelectedUseCase');
      }
    },
  },
};
</script>

<style scoped>
.usecase-list {
  height: calc(100vh - 200px);
}
.scrollable-content {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
